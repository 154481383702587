import React from "react";
import firstImage from "../assets/Image/Luzy-Aziz-Image-1.jpeg";
import firstImage1 from "../assets/Image/unnamed (2).png";
import firstImage3 from "../assets/Image/unnamed (1).png";
import firstImage4 from "../assets/Image/unnamed.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import Testimonialsslider from "../components/testimonialsslider";
import Slider from "react-slick";
const Testimonials = () => {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    // rows: 2,
    // slidesPerRow: 2,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          rows: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          rows: 1,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };



  return (
    <div className="testimonialboxcon">
      <div className="container testimonials-container">
        <h1 id="testimonials">TESTIMONIALS</h1>
        <h4>WHAT THE INDUSTRY SAY'S</h4>
        <div className="row tistorow">
          {/* <div id="testosliderlg" className="col-12">
            <div
              id="carouselExampleControls"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div className="container">
                    <div className="row testobox">
                      <div className="col-sm-6">
                        <div className="card">
                          <div className="testo-img">
                            <img className="courceimg" src={firstImage4} />
                          </div>
                          <h5>Mr. K.Kumar</h5>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            Assistant Director of Sales
                          </p>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            -J W Marriot, Hyderabad
                          </p>
                          <p style={{ fontSize: "smaller" }}>
                            "The students of ISHM are really a class apart.
                            Iwish them all the very best in the near future."
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="card">
                          <div className="testo-img">
                            <img className="courceimg" src={firstImage3} />
                          </div>
                          <h5>Mr. K.Kumar</h5>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            Assistant Director of Sales
                          </p>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            -J W Marriot, Hyderabad
                          </p>
                          <p style={{ fontSize: "smaller" }}>
                            "The students of ISHM are really a class apart.
                            Iwish them all the very best in the near future."
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row testobox">
                      <div className="col-sm-6">
                        <div className="card">
                          <div className="testo-img">
                            <img className="courceimg" src={firstImage} />
                          </div>
                          <h5>Mr. K.Kumar</h5>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            Assistant Director of Sales
                          </p>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            -J W Marriot, Hyderabad
                          </p>
                          <p style={{ fontSize: "smaller" }}>
                            "The students of ISHM are really a class apart.
                            Iwish them all the very best in the near future."
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="card">
                          <div className="testo-img">
                            <img className="courceimg" src={firstImage1} />
                          </div>
                          <h5>Mr. K.Kumar</h5>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            Assistant Director of Sales
                          </p>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            -J W Marriot, Hyderabad
                          </p>
                          <p style={{ fontSize: "smaller" }}>
                            "The students of ISHM are really a class apart.
                            Iwish them all the very best in the near future."
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="container">
                    <div className="row testobox">
                      <div className="col-sm-6">
                        <div className="card">
                          <div className="testo-img">
                            <img className="courceimg" src={firstImage4} />
                          </div>
                          <h5>Mr. K.Kumar</h5>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            Assistant Director of Sales
                          </p>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            -J W Marriot, Hyderabad
                          </p>
                          <p style={{ fontSize: "smaller" }}>
                            "The students of ISHM are really a class apart.
                            Iwish them all the very best in the near future."
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="card">
                          <div className="testo-img">
                            <img className="courceimg" src={firstImage3} />
                          </div>
                          <h5>Mr. K.Kumar</h5>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            Assistant Director of Sales
                          </p>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            -J W Marriot, Hyderabad
                          </p>
                          <p style={{ fontSize: "smaller" }}>
                            "The students of ISHM are really a class apart.
                            Iwish them all the very best in the near future."
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row testobox">
                      <div className="col-sm-6">
                        <div className="card">
                          <div className="testo-img">
                            <img className="courceimg" src={firstImage1} />
                          </div>
                          <h5>Mr. K.Kumar</h5>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            Assistant Director of Sales
                          </p>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            -J W Marriot, Hyderabad
                          </p>
                          <p style={{ fontSize: "smaller" }}>
                            "The students of ISHM are really a class apart.
                            Iwish them all the very best in the near future."
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="card">
                          <div className="testo-img">
                            <img className="courceimg" src={firstImage} />
                          </div>
                          <h5>Mr. K.Kumar</h5>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            Assistant Director of Sales
                          </p>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            -J W Marriot, Hyderabad
                          </p>
                          <p style={{ fontSize: "smaller" }}>
                            "The students of ISHM are really a class apart.
                            Iwish them all the very best in the near future."
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="container">
                    <div className="row testobox">
                      <div className="col-sm-6">
                        <div className="card">
                          <div className="testo-img">
                            <img className="courceimg" src={firstImage4} />
                          </div>
                          <h5>Mr. K.Kumar</h5>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            Assistant Director of Sales
                          </p>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            -J W Marriot, Hyderabad
                          </p>
                          <p style={{ fontSize: "smaller" }}>
                            "The students of ISHM are really a class apart.
                            Iwish them all the very best in the near future."
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="card">
                          <div className="testo-img">
                            <img className="courceimg" src={firstImage3} />
                          </div>
                          <h5>Mr. K.Kumar</h5>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            Assistant Director of Sales
                          </p>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            -J W Marriot, Hyderabad
                          </p>
                          <p style={{ fontSize: "smaller" }}>
                            "The students of ISHM are really a class apart.
                            Iwish them all the very best in the near future."
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row testobox">
                      <div className="col-sm-6">
                        <div className="card">
                          <div className="testo-img">
                            <img className="courceimg" src={firstImage1} />
                          </div>
                          <h5>Mr. K.Kumar</h5>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            Assistant Director of Sales
                          </p>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            -J W Marriot, Hyderabad
                          </p>
                          <p style={{ fontSize: "smaller" }}>
                            "The students of ISHM are really a class apart.
                            Iwish them all the very best in the near future."
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="card">
                          <div className="testo-img">
                            <img className="courceimg" src={firstImage} />
                          </div>
                          <h5>Mr. K.Kumar</h5>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            Assistant Director of Sales
                          </p>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            -J W Marriot, Hyderabad
                          </p>
                          <p style={{ fontSize: "smaller" }}>
                            "The students of ISHM are really a class apart.
                            Iwish them all the very best in the near future."
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nevi-butn">
                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev"
                >
                  <span class="arros" aria-hidden="true">
                    <i class="bi bi-arrow-left-short"></i>{" "}
                  </span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="next"
                >
                  <span class="arros" aria-hidden="true">
                    <i class="bi bi-arrow-right-short"></i>{" "}
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div id="testoslidersm" className="col-12">
            <div
              id="carouselExampleControls2"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div className="container">
                    <div className="row testobox">
                      <div className="col-12">
                        <div className="card">
                          <div className="testo-img">
                            <img className="courceimg" src={firstImage4} />
                          </div>
                          <h5>Mr. K.Kumar</h5>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            Assistant Director of Sales
                          </p>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            -J W Marriot, Hyderabad
                          </p>
                          <p style={{ fontSize: "smaller" }}>
                            "The students of ISHM are really a class apart.
                            Iwish them all the very best in the near future."
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="container">
                    <div className="row testobox">
                      <div className="col-12">
                        <div className="card">
                          <div className="testo-img">
                            <img className="courceimg" src={firstImage4} />
                          </div>
                          <h5>Mr. K.Kumar</h5>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            Assistant Director of Sales
                          </p>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            -J W Marriot, Hyderabad
                          </p>
                          <p style={{ fontSize: "smaller" }}>
                            "The students of ISHM are really a class apart.
                            Iwish them all the very best in the near future."
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="container">
                    <div className="row testobox">
                      <div className="col-12">
                        <div className="card">
                          <div className="testo-img">
                            <img className="courceimg" src={firstImage4} />
                          </div>
                          <h5>Mr. K.Kumar</h5>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            Assistant Director of Sales
                          </p>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            -J W Marriot, Hyderabad
                          </p>
                          <p style={{ fontSize: "smaller" }}>
                            "The students of ISHM are really a class apart.
                            Iwish them all the very best in the near future."
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="container">
                    <div className="row testobox">
                      <div className="col-12">
                        <div className="card">
                          <div className="testo-img">
                            <img className="courceimg" src={firstImage4} />
                          </div>
                          <h5>Mr. K.Kumar</h5>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            Assistant Director of Sales
                          </p>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            -J W Marriot, Hyderabad
                          </p>
                          <p style={{ fontSize: "smaller" }}>
                            "The students of ISHM are really a class apart.
                            Iwish them all the very best in the near future."
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="container">
                    <div className="row testobox">
                      <div className="col-12">
                        <div className="card">
                          <div className="testo-img">
                            <img className="courceimg" src={firstImage4} />
                          </div>
                          <h5>Mr. K.Kumar</h5>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            Assistant Director of Sales
                          </p>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            -J W Marriot, Hyderabad
                          </p>
                          <p style={{ fontSize: "smaller" }}>
                            "The students of ISHM are really a class apart.
                            Iwish them all the very best in the near future."
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="container">
                    <div className="row testobox">
                      <div className="col-12">
                        <div className="card">
                          <div className="testo-img">
                            <img className="courceimg" src={firstImage4} />
                          </div>
                          <h5>Mr. K.Kumar</h5>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            Assistant Director of Sales
                          </p>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            -J W Marriot, Hyderabad
                          </p>
                          <p style={{ fontSize: "smaller" }}>
                            "The students of ISHM are really a class apart.
                            Iwish them all the very best in the near future."
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="container">
                    <div className="row testobox">
                      <div className="col-12">
                        <div className="card">
                          <div className="testo-img">
                            <img className="courceimg" src={firstImage4} />
                          </div>
                          <h5>Mr. K.Kumar</h5>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            Assistant Director of Sales
                          </p>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            -J W Marriot, Hyderabad
                          </p>
                          <p style={{ fontSize: "smaller" }}>
                            "The students of ISHM are really a class apart.
                            Iwish them all the very best in the near future."
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="container">
                    <div className="row testobox">
                      <div className="col-12">
                        <div className="card">
                          <div className="testo-img">
                            <img className="courceimg" src={firstImage4} />
                          </div>
                          <h5>Mr. K.Kumar</h5>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            Assistant Director of Sales
                          </p>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            -J W Marriot, Hyderabad
                          </p>
                          <p style={{ fontSize: "smaller" }}>
                            "The students of ISHM are really a class apart.
                            Iwish them all the very best in the near future."
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="container">
                    <div className="row testobox">
                      <div className="col-12">
                        <div className="card">
                          <div className="testo-img">
                            <img className="courceimg" src={firstImage4} />
                          </div>
                          <h5>Mr. K.Kumar</h5>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            Assistant Director of Sales
                          </p>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            -J W Marriot, Hyderabad
                          </p>
                          <p style={{ fontSize: "smaller" }}>
                            "The students of ISHM are really a class apart.
                            Iwish them all the very best in the near future."
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div className="container">
                    <div className="row testobox">
                      <div className="col-12">
                        <div className="card">
                          <div className="testo-img">
                            <img className="courceimg" src={firstImage4} />
                          </div>
                          <h5>Mr. K.Kumar</h5>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            Assistant Director of Sales
                          </p>
                          <p
                            style={{
                              fontSize: "small",
                              fontWeight: "bold",
                            }}
                          >
                            -J W Marriot, Hyderabad
                          </p>
                          <p style={{ fontSize: "smaller" }}>
                            "The students of ISHM are really a class apart.
                            Iwish them all the very best in the near future."
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nevi-butn">
                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleControls2"
                  data-bs-slide="prev"
                >
                  <span class="arros" aria-hidden="true">
                    <i class="bi bi-arrow-left-short"></i>{" "}
                  </span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleControls2"
                  data-bs-slide="next"
                >
                  <span class="arros" aria-hidden="true">
                    <i class="bi bi-arrow-right-short"></i>{" "}
                  </span>
                </button>
              </div>
            </div>
          </div> */}

          <Slider {...settings}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item, index) => {
              return (
                <div className="testocard" key={index}>
                  <div class="testimonial-card">
                    <div class="text">
                      <p>
                        {" "}
                        "The students of ISHM are really a class apart. Iwish
                        them all the very best in the near future."
                      </p>
                      <i class="fas fa-quote-right quote"></i>
                    </div>
                    <div class="footert">
                      <div class="image">
                        <img className="courceimg" src={firstImage4} />
                      </div>
                      <h3 class="person">Mr. K.Kumar</h3>
                      <h2>Assistant Director of Sales</h2>
                      
                      <p className="joker"> -J W Marriot, Hyderabad </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
