import React, { useState, useEffect } from "react";
import firstImage from "../assets/Image/IMG_0102.jpg";
import firstImage1 from "../assets/Image/IMG_9589-2.jpg";
import firstImage2 from "../assets/Image/DSC05178.JPG";
import firstImage3 from "../assets/Image/bannerimg4.png";
import firstImage4 from "../assets/Image/IMG-20220326-WA0021.jpg";
import firstImage5 from "../assets/Image/HUL_6036.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Margin } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
const Coursespage = () => {
  const [showBiggestArrow, setshowBiggestArrow] = useState(true);
  const [showSmallestArrow, setshowSmallestArrow] = useState(true);
  const [showlowestArrow, setshowlowestArrow] = useState(true);

  // setInterval(() => {
  //   setTimeout(() => {
  //     setshowBiggestArrow(!showBiggestArrow);
  //   }, 1000);
  //   setTimeout(() => {
  //     setshowSmallestArrow(!showSmallestArrow);
  //   }, 2000);
  // }, 3000);
  // setTimeout(() => {
  //   setInterval(() => {
  //
  //   }, 3000);
  // }, 3000);
  // setInterval(() => {
  //   setshowBiggestArrow(!showBiggestArrow);
  // }, 4500);
  // setInterval(() => {
  //   setshowSmallestArrow(!showSmallestArrow);
  // }, 3500);

  // useEffect(() => {
  //   setInterval(() => {
  //     setshowlowestArrow(!showlowestArrow);
  //   }, 2000);
  // }, [showlowestArrow]);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <div className="coursespage">
      <div className="coursespagebanner">
        <div className="coursespagebannerimg">
          <img src={firstImage} alt="banner" />
        </div>
        <div className="coursespagebannecon">
          <div>
            <h1>
              <strong>OUR</strong> COURSES
            </h1>
            <p>
              Home
              <ChevronRightIcon />
              Courses
            </p>
          </div>
        </div>
      </div>
      <div className="container conpadding">
        <h1>MHA in Hospital Management</h1>
        <div className="row fgsas">
          <div className="col-md-7">
            <div className="row">
              <div className="col-sm-4">
                <h6>Program Duration</h6>
                <p> 3 Year</p>
              </div>
              <div className="col-sm-4">
                <h6>Eligibility</h6>
                <p>12 Pass</p>
              </div>
              <div className="col-sm-4">
                <h6>Fee</h6>
                <p>&#8377;3,60,000</p>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <p className="applybtn">
              <button>APPLY NOW</button>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-7">
            <div className="coursespageimg">
              <img src={firstImage1} alt="course" />
            </div>
          </div>
          <div className="col-md-5 ushdi">
            <div className="coursespagetabile">
              <h4>Facilities and Training:</h4>
              <p className="descrivtion">The diverse career opportunities in this field includes-</p>
              <div className="row">
                <div className="colimg4 ">
                  <div className="coursespagerelatedimg">
                     {/* <img src={firstImage2} alt="course" /> */}
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="coursespagerelatedcard">
                    <p>Hospital Operations</p>
                    {/* <p>1 year</p> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="colimg4">
                  <div className="coursespagerelatedimg">
                     {/* <img src={firstImage2} alt="course" /> */}
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="coursespagerelatedcard">
                    <p>NGO’s– Health Project Management</p>
                    {/* <p>3 year</p> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="colimg4">
                  <div className="coursespagerelatedimg">
                     {/* <img src={firstImage2} alt="course" /> */}
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="coursespagerelatedcard">
                    <p>Healthcare Consultancy Planning  & Designing Hospitals</p>
                    {/* <p>3 year</p> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="colimg4">
                  <div className="coursespagerelatedimg">
                     {/* <img src={firstImage2} alt="course" /> */}
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="coursespagerelatedcard">
                    <p>Hospital Information System</p>
                    {/* <p>1 year</p> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="colimg4">
                  <div className="coursespagerelatedimg">
                     {/* <img src={firstImage2} alt="course" /> */}
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="coursespagerelatedcard">
                    <p>Healthcare Insurance</p>
                    {/* <p>1 year</p> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="colimg4">
                  <div className="coursespagerelatedimg">
                     {/* <img src={firstImage2} alt="course" /> */}
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="coursespagerelatedcard">
                    <p>Medical Tourism </p>
                    {/* <p>1 year</p> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="colimg4">
                  <div className="coursespagerelatedimg">
                     {/* <img src={firstImage2} alt="course" /> */}
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="coursespagerelatedcard">
                    <p>Tele-Medicine</p>
                    {/* <p>1 year</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="coursespagecontainheader col-md-7">
            <p>&#8594; Description Facilties & Training Instructors</p>
          </div>
          <div className="coursespagecontain">
            <h3>Course Overview</h3>
            <p>
            MHA (Master of Hospital Administration) is equal to and more than MBA in Healthcare. A suitable work experience can always give students higher employment prospects vis-à-vis a fresh graduate, quite like MBA compared to other graduates. With NABH and other quality drives in hospitals and healthcare delivery organizations, there is now a growing demand to employ MHA (Master of Hospital Administration) qualified people.
            </p>
            <p>
            Healthcare has become one of India’s largest sectors – both in terms of revenue and employment. The Indian healthcare sector is growing at a brisk pace due to its strengthening coverage, services and increasing expenditure by public as well private players. The overall Indian healthcare market today is worth US$ 100 billion and is expected to grow to US$ 280 billion by 2020, a compound annual growth rate (CAGR) of 22.9 per cent.
            </p>
          </div>
          <div className="coursespagefaqs">
            <h5>FAQS</h5>
            <ul>
              <li>
                <ChevronRightIcon className="muiicon" /> What we do
              </li>
              <li>
                <ChevronRightIcon className="muiicon" /> Featured events
              </li>
              <li>
                <ChevronRightIcon className="muiicon" /> I'm already there
              </li>
              <li>
                <ChevronRightIcon className="muiicon" /> Do i need to take the
                courses?
              </li>
            </ul>
            <p className="contactu">For more detalis contact us:</p>
            <p className="contacs">Call or WhatsApp us at +91 9007070004</p>
            <div className="coursespagesocial">
              <p>Share with:</p>
              <a>
                <FacebookIcon className="muiicon" />
              </a>
              <a>
                <InstagramIcon className="muiicon" />
              </a>
              <a>
                <TwitterIcon className="muiicon" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coursespage;

{
  /* <div className="item" data-aos="fade-up">1</div>
<div className="item" data-aos="fade-down">2</div>
<div className="item" data-aos="fade-right">3</div>
<div className="item" data-aos="fade-left">4</div>

<div className="item" data-aos="zoom-in">5</div>
<div className="item" data-aos="zoom-out"></div>

<div className="item" data-aos="slide-up">7</div>

<div className="item" data-aos="flip-up">8</div>
<div className="item" data-aos="flip-down">9</div>
<div className="item" data-aos="flip-right">10</div>
<div className="item" data-aos="flip-left">11</div> */
}
