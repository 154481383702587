import React, { useState, useEffect } from "react";
import firstImage from "../assets/Image/IMG_0102.jpg";
import firstImage1 from "../assets/Image/IMG_9589-2.jpg";
import firstImage2 from "../assets/Image/DSC05178.JPG";
import firstImage3 from "../assets/Image/bannerimg4.png";
import firstImage4 from "../assets/Image/IMG-20220326-WA0021.jpg";
import firstImage5 from "../assets/Image/HUL_6036.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
// dropdown menu on hover bootstrap5

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Margin } from "@mui/icons-material";

const Courses = () => {
  const [showBiggestArrow, setshowBiggestArrow] = useState(true);
  const [showSmallestArrow, setshowSmallestArrow] = useState(true);
  const [showlowestArrow, setshowlowestArrow] = useState(true);

  // setInterval(() => {
  //   setTimeout(() => {
  //     setshowBiggestArrow(!showBiggestArrow);
  //   }, 1000);
  //   setTimeout(() => {
  //     setshowSmallestArrow(!showSmallestArrow);
  //   }, 2000);
  // }, 3000);
  // setTimeout(() => {
  //   setInterval(() => {
  //
  //   }, 3000);
  // }, 3000);
  // setInterval(() => {
  //   setshowBiggestArrow(!showBiggestArrow);
  // }, 4500);
  // setInterval(() => {
  //   setshowSmallestArrow(!showSmallestArrow);
  // }, 3500);

  // useEffect(() => {
  //   setInterval(() => {
  //     setshowlowestArrow(!showlowestArrow);
  //   }, 2000);
  // }, [showlowestArrow]);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <div className="coursesparentdiv">
      <div data-aos="fade-down" className="container courseContainer">
        <div className="row">
          <div className="col-md-12">
            <div className="courses-title">
              <h2 data-aos="fade-up" style={{ color: "white" }}>
                <span style={{ fontWeight: "bold" }} id="courses">
                  OUR
                </span>{" "}
                COURSES
              </h2>
            </div>
          </div>
        </div>

        <div className="corscontainer">
          <div className="row courcebox" >

            
            <div data-aos="flip-down" className="col-md-6 col-lg-4 ">
            {" "}
              <div className="courseimgbox">
                <img className="courceimg" src={firstImage} />
              </div>
              <span className="boxopa"></span>
              <p>Diploma in Aviation, Hotel & Hospitality Managent (1 Year)</p>
              <button>Explore More</button>
            </div>

            <div data-aos="flip-up" className="col-md-6 col-lg-4 ">
            {" "}
              
              <div className="courseimgbox">
                
                <img className="courceimg" src={firstImage1} />
              </div>
              <span className="boxopa"></span>
              <p>
                Advance Diploma in Aviation, Hotel & Hospitality Management+BTS
                (3 Years)
              </p>
              <button>Explore More</button>
            </div>
            <div data-aos="slide-up" className="col-md-6 col-lg-4">
              {" "}
              <div className="courseimgbox">
              <img className="courceimg" src={firstImage2} />
              </div>
              <span className="boxopa"></span>
              <p>
                PGPM in Hospitality Management <br></br>(2 Years)
              </p>
              <button>Explore More</button>
            </div>

            <div data-aos="zoom-out" className="col-md-6 col-lg-4">
              {" "}
              <div className="courseimgbox">
              <img className="courceimg" src={firstImage3} />
              </div>
              <span className="boxopa"></span>
              <p>
                BBA IN AVIATION MANAGEMENT<br></br> (3 Years)
              </p>
              <button>Explore More</button>
            </div>
            <div data-aos="fade-right" className="col-md-6 col-lg-4">
              {" "}
              <div className="courseimgbox">
              <img className="courceimg" src={firstImage4} />
              </div>
              <span className="boxopa"></span>
              <p>
                B.Sc in Hospitality Adminstration<br></br> (3 Years)
              </p>
              <button>Explore More</button>
            </div>
            <div data-aos="zoom-in" className="col-md-6 col-lg-4">
              {" "}
              <div className="courseimgbox">
              <img className="courceimg" src={firstImage5} />
              </div>
               <span className="boxopa"></span>
              <p>
                MHA in Hospitality Management <br></br> (3 Years)
              </p>
              <button>Explore More</button>
            </div>
          </div>
        </div>
      </div>
      <div className="container arrocon">
        <div className="row" style={{ height: "80px", textAlign: "center" }}>
          <div
            className="col-md-12"
            style={{ lineHeight: "0px", height: "10px" }}
          >
            {" "}
            {showSmallestArrow ? (
              <ExpandMoreIcon
                style={{
                  height: "50px",
                  color: "white",
                  width: "100px",
                }}
              />
            ) : (
              ""
            )}
          </div>
          {showBiggestArrow ? (
            <div className="col-md-12" style={{ height: "30px" }}>
              {" "}
              <ExpandMoreIcon
                style={{
                  height: "70px",
                  color: "white",
                  width: "100px",
                }}
              />
            </div>
          ) : (
            <div className="col-md-12" style={{ height: "30px" }}>
              {" "}
            </div>
          )}
          {showlowestArrow ? (
            <div className="col-md-12">
              {" "}
              <ExpandMoreIcon
                style={{
                  height: "50px",
                  color: "white",
                  width: "100px",
                }}
              />
            </div>
          ) : (
            <div className="col-md-12" style={{ height: "10px" }}>
              {" "}
            </div>
          )}
        </div>
      </div>

      {/* <div className="item" data-aos="fade-up">1</div>
<div className="item" data-aos="fade-down">2</div>
<div className="item" data-aos="fade-right">3</div>
<div className="item" data-aos="fade-left">4</div>

<div className="item" data-aos="zoom-in">5</div>
<div className="item" data-aos="zoom-out"></div>

<div className="item" data-aos="slide-up">7</div>

<div className="item" data-aos="flip-up">8</div>
<div className="item" data-aos="flip-down">9</div>
<div className="item" data-aos="flip-right">10</div>
<div className="item" data-aos="flip-left">11</div> */}
    </div>
  );
};

export default Courses;
