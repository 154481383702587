import React from "react";
import banner1 from "../assets/Image/bannerimg1.png";
import banner2 from "../assets/Image/bannerimg2.png";
import banner3 from "../assets/Image/bannerimg3.png";
import banner4 from "../assets/Image/bannerimg4.png";
import IshmLogo from "../assets/Image/white_logo.png";
import GoogleImg from "../assets/Image/google.png";
import AppleImg from "../assets/Image/apple.png";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";

const Footer = () => {
  return (
    <div className="footerparent">
      <div className="container" style={{ paddingTop: "0px" }}>
        <img
          src={IshmLogo}
          alt=""
          style={{ height: "50px", width: "200px", objectFit: "cover" }}
        />
        <footer>
          <div className="row">
            <div className="col-lg-6 footercontaint">
              <p>
                The International School of Hospitality Management (ISHM) is one
                of the fastest growing & most promising Hospitality Management
                Institute in Kolkata. ISHM programs are based on the philosophy
                that a commitment to excellence and nest education and training
                are essential for those who are seeking the most sought-after
                positions in the highly competitive hospitality industry.
              </p>
              <button>READ MORE</button>
              <div className="socialicons">
                <p>Get in touch</p>
                <FacebookIcon className="sicon" />
                <InstagramIcon className="sicon" />
                <TwitterIcon className="sicon" />
              </div>
            </div>
            <div className="col-lg-6 footersenditem">
              <div className="row">
                <div className="col-sm-4">
                  <ul
                    className="footeritem"
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    <p>Information</p>
                    <li style={{ color: "white", textDecoration: "none" }}>
                      Study Aboard
                    </li>
                    <li style={{ color: "white", textDecoration: "none" }}>
                      International Student Cell
                    </li>
                    <li style={{ color: "white", textDecoration: "none" }}>
                      Our Courses
                    </li>
                    <li style={{ color: "white", textDecoration: "none" }}>
                      Gallery
                    </li>
                    <li style={{ color: "white", textDecoration: "none" }}>
                      Testimonials
                    </li>
                    <li style={{ color: "white", textDecoration: "none" }}>
                      Placements
                    </li>
                    <li style={{ color: "white", textDecoration: "none" }}>
                      News & Notice
                    </li>
                  </ul>
                </div>
                <div className="col-sm-4 footeritem">
                  <ul>
                    <p style={{ color: "white", textDecoration: "none" }}>
                      Support
                    </p>
                    <li style={{ color: "white", textDecoration: "none" }}>
                      Online Exam
                    </li>
                    <li style={{ color: "white", textDecoration: "none" }}>
                      Student Portel
                    </li>
                    <li style={{ color: "white", textDecoration: "none" }}>
                      international Pay
                    </li>
                    <li style={{ color: "white", textDecoration: "none" }}>
                      Privacy Policy
                    </li>
                  </ul>
                </div>
                <div className="col-sm-4">
                  <ul className="footericons">
                    {/* <p style={{ color: "white" }}>Support</p> */}
                    <li>
                      <a href="/">
                        <img
                          src={GoogleImg}
                          className=" custoapplogo"
                          alt="..."
                        ></img>
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <img
                          src={AppleImg}
                          className="custoapplogo"
                          alt="..."
                        ></img>
                      </a>
                    </li>
                    <li>
                      <button className="payfee">PAY FEES</button>
                    </li>
                    <li>
                      {" "}
                      <button
                        className="downtoup"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <ArrowUpwardIcon />
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12 foterdisc">
                <p className="text-center" style={{ color: "white" }}>
                  ©2022 ISHM. All rights reseved.Designed & Developed by Kazma
                  Technology.
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
