// import React from "react";
import React, { useEffect } from "react";
import banner1 from "../assets/Image/IMG_4582.jpg";
import banner2 from "../assets/Image/IMG_9709.jpg";


import banner3 from "../assets/Image/DSC05253.JPG";
import banner4 from "../assets/Image/DSC05178.JPG";

import "bootstrap-icons/font/bootstrap-icons.css";
import AOS from "aos";
import "aos/dist/aos.css";

const StudyAbroad = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <div className="study-container">
      <div className="container ">
        <div className="row">
          <div className="col-md-7">
            <div className="card studycard">
              <h1 data-aos="flip-up">
                <strong id="studyabroad">Study</strong> Abroad
              </h1>
              <p data-aos="fade-down">
                ISHM is offering a Earn & Learn Programe in "Hotel Management"
                which was previously available to only Malaysian students but
                now open to international students as well. During the course
                tenure, the students will be placed in 4 stars & 5 start hotels
                for internship where they can a handsome amount as an internee
                while getting practical training. This program creates to
                enhance their skills in the area of food & beverage and hotel
                management where they shall be placed at any FIne-Dine
                restaurant, Bistros, Cafes and any 5 star hotels in Malaysia.
              </p>
              <button>INTERNATIONAL STUDENT CELL</button>
            </div>
          </div>
          <div className="col-md-5 ">
            <div id="studysliderlg" className="card carosel-card">
              <div
                id="carouselExampleInterval"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active" data-bs-interval="10000">
                    <div className="caroulcardbox">
                      <div className="card-body study-body">
                        <img
                          data-aos="zoom-out"
                          src={banner1}
                          alt="banner"
                          className="ishmbannerimg"
                        />
                        <h3 data-aos="zoom-in">Earn & learn in malaysia</h3>
                      </div>
                      <div className="card-body study-body">
                        <img
                          data-aos="zoom-out"
                          src={banner2}
                          alt="banner"
                          className="ishmbannerimg"
                        />
                        <h3 data-aos="zoom-in">Study Tour in Thailand</h3>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item" data-bs-interval="2000">
                    <div className="caroulcardbox">
                      <div className="card-body study-body">
                        <img
                          data-aos="zoom-out"
                          src={banner3}
                          alt="banner"
                          className="ishmbannerimg"
                        />
                        <h3 data-aos="zoom-in">Earn & learn in malaysia</h3>
                      </div>
                      <div className="card-body study-body">
                        <img
                          data-aos="zoom-out"
                          src={banner4}
                          alt="banner"
                          className="ishmbannerimg"
                        />
                        <h3 data-aos="zoom-in">Study Tour in Thailand</h3>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div className="caroulcardbox">
                      <div className="card-body study-body">
                        <img
                          data-aos="zoom-out"
                          src={banner4}
                          alt="banner"
                          className="ishmbannerimg"
                        />
                        <h3 data-aos="zoom-in">Earn & learn in malaysia</h3>
                      </div>
                      <div className="card-body study-body">
                        <img
                          data-aos="zoom-out"
                          src={banner2}
                          alt="banner"
                          className="ishmbannerimg"
                        />
                        <h3 data-aos="zoom-in">Study Tour in Thailand</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleInterval"
                  data-bs-slide="prev"
                >
                  <span class="carrow" aria-hidden="true">
                    <i data-aos="flip-up" class="bi bi-arrow-left-short"></i>{" "}
                  </span>
                </button>
                <button
                  class="carousel-control-next study-next"
                  type="button"
                  data-bs-target="#carouselExampleInterval"
                  data-bs-slide="next"
                >
                  <span class="carrow" aria-hidden="true">
                    <i
                      data-aos="flip-right"
                      class="bi bi-arrow-right-short"
                    ></i>{" "}
                  </span>
                </button>
              </div>
            </div>
            <div  id="studyslidersm" className="card carosel-card">
              <div
                id="carouselExampleInterval2"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <div class="carousel-inner">
                  <div class="carousel-item active" data-bs-interval="10000">
                    <div className="caroulcardbox">
                      <div className="card-body study-body">
                        <img
                          data-aos="zoom-out"
                          src={banner1}
                          alt="banner"
                          className="ishmbannerimg"
                        />
                        <h3 data-aos="zoom-in">Earn & learn in malaysia</h3>
                      </div>                      
                    </div>
                  </div>
                  <div class="carousel-item" data-bs-interval="10000">
                    <div className="caroulcardbox">
                      <div className="card-body study-body">
                        <img
                          data-aos="zoom-out"
                          src={banner1}
                          alt="banner"
                          className="ishmbannerimg"
                        />
                        <h3 data-aos="zoom-in">Earn & learn in malaysia</h3>
                      </div>                      
                    </div>
                  </div>
                  <div class="carousel-item" data-bs-interval="10000">
                    <div className="caroulcardbox">
                      <div className="card-body study-body">
                        <img
                          data-aos="zoom-out"
                          src={banner1}
                          alt="banner"
                          className="ishmbannerimg"
                        />
                        <h3 data-aos="zoom-in">Earn & learn in malaysia</h3>
                      </div>                      
                    </div>
                  </div>
                  <div class="carousel-item" data-bs-interval="10000">
                    <div className="caroulcardbox">
                      <div className="card-body study-body">
                        <img
                          data-aos="zoom-out"
                          src={banner1}
                          alt="banner"
                          className="ishmbannerimg"
                        />
                        <h3 data-aos="zoom-in">Earn & learn in malaysia</h3>
                      </div>                      
                    </div>
                  </div>
                  <div class="carousel-item" data-bs-interval="10000">
                    <div className="caroulcardbox">
                      <div className="card-body study-body">
                        <img
                          data-aos="zoom-out"
                          src={banner1}
                          alt="banner"
                          className="ishmbannerimg"
                        />
                        <h3 data-aos="zoom-in">Earn & learn in malaysia</h3>
                      </div>                      
                    </div>
                  </div>
                  <div class="carousel-item" data-bs-interval="10000">
                    <div className="caroulcardbox">
                      <div className="card-body study-body">
                        <img
                          data-aos="zoom-out"
                          src={banner1}
                          alt="banner"
                          className="ishmbannerimg"
                        />
                        <h3 data-aos="zoom-in">Earn & learn in malaysia</h3>
                      </div>                      
                    </div>
                  </div>
                  <div class="carousel-item" data-bs-interval="10000">
                    <div className="caroulcardbox">
                      <div className="card-body study-body">
                        <img
                          data-aos="zoom-out"
                          src={banner1}
                          alt="banner"
                          className="ishmbannerimg"
                        />
                        <h3 data-aos="zoom-in">Earn & learn in malaysia</h3>
                      </div>                      
                    </div>
                  </div>
                  <div class="carousel-item" data-bs-interval="10000">
                    <div className="caroulcardbox">
                      <div className="card-body study-body">
                        <img
                          data-aos="zoom-out"
                          src={banner1}
                          alt="banner"
                          className="ishmbannerimg"
                        />
                        <h3 data-aos="zoom-in">Earn & learn in malaysia</h3>
                      </div>                      
                    </div>
                  </div>
                  <div class="carousel-item" data-bs-interval="10000">
                    <div className="caroulcardbox">
                      <div className="card-body study-body">
                        <img
                          data-aos="zoom-out"
                          src={banner1}
                          alt="banner"
                          className="ishmbannerimg"
                        />
                        <h3 data-aos="zoom-in">Earn & learn in malaysia</h3>
                      </div>                      
                    </div>
                  </div>
                 
                </div>
                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleInterval2"
                  data-bs-slide="prev"
                >
                  <span class="carrow" aria-hidden="true">
                    <i data-aos="flip-up" class="bi bi-arrow-left-short"></i>{" "}
                  </span>
                </button>
                <button
                  class="carousel-control-next study-next"
                  type="button"
                  data-bs-target="#carouselExampleInterval2"
                  data-bs-slide="next"
                >
                  <span class="carrow" aria-hidden="true">
                    <i
                      data-aos="flip-right"
                      class="bi bi-arrow-right-short"
                    ></i>{" "}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudyAbroad;

// {/* <div className="item" data-aos="fade-up">1</div>
// <div className="item" data-aos="fade-down">2</div>
// <div className="item" data-aos="fade-right">3</div>
// <div className="item" data-aos="fade-left">4</div>

// <div className="item" data-aos="zoom-in">5</div>
// <div className="item" data-aos="zoom-out"></div>

// <div className="item" data-aos="slide-up">7</div>

// <div className="item" data-aos="flip-up">8</div>
// <div className="item" data-aos="flip-down">9</div>
// <div className="item" data-aos="flip-right">10</div>
// <div className="item" data-aos="flip-left">11</div> */}
