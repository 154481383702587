import React, { useState, useEffect } from "react";
import logo from "../assets/Image/logo.jpg";
import "bootstrap-icons/font/bootstrap-icons.css";
import CallIcon from "@mui/icons-material/Call";
import courseImg from "../assets/Image/giphy (1).gif";
import PlacementImg from "../assets/Image/giphy.gif";
import AOS from "aos";
import "aos/dist/aos.css";
import { navigate } from "@reach/router";

import {
  Button,
  Grid,
  Container,
  Box,
  Typography,
  Link,
  Divider,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import TestimonialImg from "../assets/Image/Luzy-Aziz-Image-1.jpeg";
import TestimonialImg2 from "../assets/Image/unnamed (2).png";
// import { useHistory } from "react-router-dom";

const Header = ({ navigate }) => {
  const [showClass, setShowClass] = useState(false);
  const [offset, setOffset] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [hideUi, setHideUi] = useState(false);
  const [showTestimonialDropDown, setShowTestimonialDropDown] = useState(false);
  const [showPlacementDropDown, setShowPlacementDropDown] = useState(false);
  const [responsive, setResponsive] = useState(false);

  // const history = useHistory();
  // const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 400) {
        setOffset(true);
      } else {
        setOffset(false);
      }
    });
  }, []);

  // const goToStudyAbroad = () => {
  //   window.scrollTo(0, 800);
  // };

  const closeBtnTrigerred = () => {
    setHideUi(true);
    // setShowClass(false);
  };

  const coursesBtnTrigerred = () => {
    setHideUi(false);

    setTimeout(() => {
      if (showClass) {
        setResponsive(true);
      } else {
        setResponsive(false);
      }

      setShowDropDown(false);
    }, 5);
    navigate("/");
    // window.location.pathname = "/";
    // window.location.hash = "#courses";
  };

  return (
    <div>
      <div
        id="headercon"
        className="header-container"
        style={{ backgroundColor: offset ? "rgb(255, 196, 0)" : "" }}
      >
        <div className=" navcontainer">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
              <a className="navbar-brand" href="/">
                <img src={logo} alt="banner" className="ishmbannerimg" />
              </a>
              {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button> */}
              <button
                className="custoggalbtn"
                onClick={() => {
                  setShowClass(!showClass);
                }}
              >
                {" "}
                <i class="bi bi-list"></i>
              </button>
              <div className="collapse navbar-collapse responsive-top">
                <div className="header-icon navbar-nav me-auto mb-2 mb-lg-0">
                  <p>Get Social</p>
                  <a className="nav-link active" aria-current="page" href="/">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a className="nav-link active" aria-current="page" href="/">
                    <i className="bi bi-twitter"></i>
                  </a>
                  <a className="nav-link active" aria-current="page" href="/">
                    <i className="bi bi-linkedin"></i>
                  </a>
                  <a className="nav-link active" aria-current="page" href="/">
                    <i className="bi bi-instagram"></i>
                  </a>
                  <a className="nav-link active" aria-current="page" href="/">
                    <i className="bi bi-pinterest"></i>
                  </a>
                  <a className="nav-link active" aria-current="page" href="/">
                    <i className="bi bi-youtube"></i>
                  </a>
                  <button className="btn cusnavbtn">ONLINE ADMISSION</button>
                  <button className="btn cusnavbtn">CALL US</button>
                </div>
              </div>
              <div
                // className="bhide  tggler-box"
                className={
                  showClass ? "bshow  tggler-box" : " bhide tggler-box"
                }
              >
                <div
                  // className="hide  collapse navbar-collapse navitemlist"
                  className={
                    showClass
                      ? "show  collapse navbar-collapse navitemlist"
                      : " hide collapse navbar-collapse navitemlist"
                  }
                  id="navbarText"
                >
                  {/* <li class="nav-item dropdown navdropdown">
                    <a
                      class="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Dropdown
                    </a>
                   
                  </li> */}
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        aria-current="page"
                        href="/"
                        onClick={() => {
                          setShowClass(false);
                        }}
                      >
                        HOME
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="/#studyabroad"
                        onClick={() => {
                          setShowClass(false);
                        }}
                        // onClick={goToStudyAbroad}
                      >
                        STUDY ABOARD
                      </a>
                    </li>
                    <li
                      // style={{ backgroundColor: "red" }}
                      className="nav-item dropdown navdropdown"
                      // onClick={() => {
                      //   setShowDropDown(false);
                      //   setTimeout(() => {
                      //     navigate("#courses");
                      //   }, 2000);
                      // }}
                    >
                      <a
                        className={
                          !showDropDown
                            ? "nav-link"
                            : "nav-link dropdown-hover show"
                        }
                        href="/#courses"
                        onClick={coursesBtnTrigerred}
                        id="navbarDropdown"
                        role="button"
                        onMouseOver={() => {
                          setShowDropDown(true);
                        }}
                        onMouseOut={() => {
                          setShowDropDown(false);
                        }}
                        data-bs-toggle="dropdown"
                        aria-expanded={showDropDown ? "true" : "false"}
                        // href="#courses"
                      >
                        COURSES
                      </a>

                      {responsive ? (
                        <Grid container>
                          {hideUi ? (
                            " "
                          ) : (
                            <>
                              {" "}
                              <h3
                                style={{
                                  textDecoration: "underline",
                                  textDecorationColor: "orangered",
                                  textDecorationLine: "3px",
                                  textAlign: "center",
                                }}
                              >
                                All Courses
                              </h3>
                              <Divider />
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <ul style={{ color: "black" }}>
                                  <li style={{ listStyle: "none" }}>
                                    <a
                                      style={{
                                        backgroundColor: "transparent",
                                        color: "white",
                                        fontWeight: "bold",
                                        border: "2px solid white",
                                        borderRadius: "50%",
                                        zIndex: "9999999999",
                                        position: "absolute",
                                        top: "40px",
                                        right: "0px",
                                        width: "25px",
                                        height: "25px",
                                        textAlign: "center",
                                      }}
                                      onClick={closeBtnTrigerred}
                                    >
                                      X
                                    </a>
                                  </li>
                                  <a
                                    href="/course_details"
                                    style={{
                                      textDecoration: "none",
                                    }}
                                  >
                                    <li
                                      style={{
                                        fontWeight: "bold",
                                        color: "white",
                                      }}
                                    >
                                      Diploma in Aviation, Hotel & Hospitality
                                      Managent{" "}
                                    </li>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      Duration: 1 Year
                                    </p>
                                    <Divider />
                                    <li
                                      style={{
                                        fontWeight: "bold",
                                        color: "white",
                                      }}
                                    >
                                      Advance Diploma in Aviation, Hotel &
                                      Hospitality Management+BTS
                                    </li>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      Duration: 3 Years
                                    </p>
                                    <Divider />
                                    <li
                                      style={{
                                        fontWeight: "bold",
                                        color: "white",
                                      }}
                                    >
                                      PGPM in Hospitality Management
                                    </li>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      Duration: 2 Years
                                    </p>
                                    <Divider />
                                    <li
                                      style={{
                                        fontWeight: "bold",
                                        color: "white",
                                      }}
                                    >
                                      BBA IN AVIATION MANAGEMENT
                                    </li>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      Duration: 3 Years
                                    </p>
                                    <Divider />
                                    <li
                                      style={{
                                        fontWeight: "bold",
                                        color: "white",
                                      }}
                                    >
                                      B.Sc in Hospitality Adminstration
                                    </li>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      Duration: 3 Years
                                    </p>
                                    <Divider />
                                    <li
                                      style={{
                                        fontWeight: "bold",
                                        color: "white",
                                      }}
                                    >
                                      MHA in Hospitality Management
                                    </li>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      Duration: 3 Year
                                    </p>
                                    <Divider />
                                  </a>
                                </ul>
                              </Grid>
                            </>
                          )}
                          <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
                        </Grid>
                      ) : (
                        <div
                          className={
                            showDropDown
                              ? "dropdown-menu show"
                              : "dropdown-menu"
                          }
                          aria-labelledby="navbarDropdown"
                          style={{
                            overflow: "hidden",
                            width: "80vh",
                            // marginTop: "1vh",
                            backgroundColor: "lightgrey",
                            marginLeft: window.innerWidth > 1443 ? "" : "-25vh",
                          }}
                          onMouseOver={() => {
                            setShowDropDown(true);
                          }}
                          onMouseOut={() => {
                            setShowDropDown(false);
                          }}
                        >
                          <Grid
                            container
                            style={{
                              padding: "15px",
                              boxShadow: "0 10px 20px hsla(0, 0%, 0%, 0.4)",
                            }}
                          >
                            <Grid
                              item
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                              textAlign="left"
                            >
                              <Box
                                display="flex"
                                justifyContent="center"
                                style={{ height: "100%", width: "100%" }}
                              >
                                <Box alignSelf="left">
                                  <h5
                                    style={{
                                      fontWeight: "bold",
                                      textDecoration: "underline",
                                      textDecorationColor: "orangered",
                                      textDecorationLine: "3px",
                                      lineHeight: "2px",
                                    }}
                                  >
                                    All Courses
                                  </h5>
                                  <a
                                    href="/course_details"
                                    style={{
                                      textDecoration: "none",
                                    }}
                                  >
                                    {" "}
                                    <ul
                                      style={{
                                        marginTop: "3vh",
                                        color: "black",
                                      }}
                                    >
                                      <li
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {" "}
                                        Diploma in Aviation, Hotel & Hospitality
                                        Managent
                                      </li>
                                      <li style={{ listStyle: "none" }}>
                                        Course Duration: 1 Year
                                      </li>
                                      <Divider />
                                      <li
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {" "}
                                        Advance Diploma in Aviation, Hotel &
                                        Hospitality Management+BTS
                                      </li>
                                      <li style={{ listStyle: "none" }}>
                                        Course Duration: 3 Years
                                      </li>
                                      <Divider />
                                      <li
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {" "}
                                        PGPM in Hospitality Management
                                      </li>
                                      <li style={{ listStyle: "none" }}>
                                        Course Duration: 2 Years
                                      </li>{" "}
                                      <Divider />
                                      <li
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {" "}
                                        BBA IN AVIATION MANAGEMENT
                                      </li>
                                      <li style={{ listStyle: "none" }}>
                                        Course Duration: 3 Years
                                      </li>
                                      <Divider />
                                    </ul>
                                  </a>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                              <Box
                                style={{
                                  overflow: "hidden",
                                  objectFit: "cover",
                                }}
                              >
                                <a
                                  href="/course_details"
                                  style={{
                                    textDecoration: "none",
                                  }}
                                >
                                  <ul
                                    style={{
                                      marginTop: "3.5vh",
                                      color: "black",
                                    }}
                                  >
                                    <li
                                      style={{
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {" "}
                                      B.Sc in Hospitality Adminstration
                                    </li>
                                    <li style={{ listStyle: "none" }}>
                                      Course Duration: 3 Years
                                    </li>
                                    <Divider />
                                  </ul>
                                </a>
                                <img
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "fill",
                                  }}
                                  src={courseImg}
                                  alt=""
                                />
                                <a
                                  href="/course_details"
                                  style={{
                                    textDecoration: "none",
                                  }}
                                >
                                  <ul style={{ marginTop: "1vh" }}>
                                    <li
                                      style={{
                                        fontWeight: "bold",
                                        color: "black",
                                      }}
                                    >
                                      {" "}
                                      MHA in Hospitality Management
                                    </li>
                                    <li
                                      style={{
                                        listStyle: "none",
                                        color: "black",
                                      }}
                                    >
                                      Course Duration: 3 Years
                                    </li>
                                    <Divider />
                                  </ul>
                                </a>
                              </Box>
                            </Grid>
                          </Grid>
                        </div>
                      )}
                    </li>

                    <li className="nav-item">
                      <a
                        className="nav-link"
                        href="/#gallery"
                        // onClick={() => {
                        //   window.scrollTo(0, 2400);
                        // }}
                        onClick={() => {
                          setShowClass(false);
                        }}
                      >
                        GALLERY
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          !showTestimonialDropDown
                            ? "nav-link"
                            : "nav-link dropdown-hover show"
                        }
                        href="/#testimonials"
                        // onClick={() => {
                        //   window.scrollTo(0, 3200);
                        // }}
                        id="navbarDropdown"
                        onClick={() => {
                          setShowClass(false);

                          setTimeout(() => {
                            setShowTestimonialDropDown(false);
                          }, 5);
                          window.location.pathname = "/";
                          window.location.hash = "#testimonials";
                        }}
                        role="button"
                        onMouseOver={() => {
                          setShowTestimonialDropDown(true);
                        }}
                        onMouseOut={() => {
                          setShowTestimonialDropDown(false);
                        }}
                        data-bs-toggle="dropdown"
                        aria-expanded={
                          showTestimonialDropDown ? "true" : "false"
                        }
                      >
                        TESTIMONIALS
                      </a>

                      <div
                        className={
                          showTestimonialDropDown
                            ? "dropdown-menu show"
                            : "dropdown-menu"
                        }
                        aria-labelledby="navbarDropdown"
                        style={{
                          overflow: "hidden",
                          width: "80vh",
                          // marginTop: "1vh",
                          backgroundColor: "lightgrey",
                          marginLeft: window.innerWidth > 1443 ? "" : "-40vh",
                        }}
                        onMouseOver={() => {
                          setShowTestimonialDropDown(true);
                        }}
                        onMouseOut={() => {
                          setShowTestimonialDropDown(false);
                        }}
                      >
                        <Grid
                          container
                          style={{
                            // padding: "15px",
                            boxShadow: "0 10px 20px hsla(0, 0%, 0%, 0.4)",
                          }}
                        >
                          <h5
                            style={{
                              fontWeight: "bold",
                              textDecoration: "underline",
                              textDecorationColor: "orangered",
                              textDecorationLine: "3px",
                              lineHeight: "2px",
                              padding: "15px",
                            }}
                          >
                            Testimonials
                          </h5>
                          <Grid
                            lg={12}
                            xs={12}
                            sm={12}
                            md={12}
                            style={{ padding: "15px" }}
                          >
                            <Card sx={{ maxWidth: "100%" }}>
                              <Box display="flex">
                                <Grid
                                  item
                                  lg={3}
                                  md={5}
                                  sm={6}
                                  xs={6}
                                  style={{ width: "100%" }}
                                >
                                  {" "}
                                  <CardMedia
                                    component="img"
                                    height="140"
                                    image={TestimonialImg}
                                    alt="green iguana"
                                  />
                                </Grid>
                                <Grid lg={9} md={7} sm={6} xs={6}>
                                  <CardContent>
                                    <Typography
                                      gutterBottom
                                      variant="h6"
                                      component="div"
                                    >
                                      Mr. K.Kumar
                                    </Typography>

                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                      fontFamily="Italic"
                                      fontWeight="bold"
                                    >
                                      "The students of ISHM are really a class
                                      apart. Iwish them all the very best in the
                                      near future."
                                    </Typography>
                                  </CardContent>
                                </Grid>
                              </Box>
                            </Card>
                          </Grid>
                          <Grid
                            lg={12}
                            xs={12}
                            sm={12}
                            md={12}
                            style={{ padding: "15px" }}
                          >
                            <Card sx={{ maxWidth: "100%" }}>
                              <Box display="flex">
                                <Grid
                                  item
                                  lg={3}
                                  md={5}
                                  sm={6}
                                  xs={6}
                                  style={{ width: "100%" }}
                                >
                                  {" "}
                                  <CardMedia
                                    component="img"
                                    height="140"
                                    image={TestimonialImg2}
                                    alt="green iguana"
                                  />
                                </Grid>
                                <Grid
                                  item
                                  lg={9}
                                  md={7}
                                  sm={6}
                                  xs={6}
                                  style={{ width: "100%" }}
                                >
                                  <CardContent>
                                    <Typography
                                      gutterBottom
                                      variant="h6"
                                      component="div"
                                    >
                                      Mr. K.Kumar
                                    </Typography>

                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                      fontFamily="Italic"
                                      fontWeight="bold"
                                    >
                                      "The students of ISHM are really a class
                                      apart. Iwish them all the very best in the
                                      near future."
                                    </Typography>
                                  </CardContent>
                                </Grid>
                              </Box>
                            </Card>
                          </Grid>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                            sm={12}
                            textAlign="center"
                          >
                            <Button
                              onClick={() => {
                                window.location.hash = "#testimonials";
                              }}
                            >
                              See More...
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          !showPlacementDropDown
                            ? "nav-link"
                            : "nav-link dropdown-hover show"
                        }
                        href="/#testimonials"
                        // onClick={() => {
                        //   window.scrollTo(0, 3200);
                        // }}
                        id="navbarDropdown"
                        onClick={() => {
                          setShowClass(false);

                          setTimeout(() => {
                            setShowPlacementDropDown(false);
                          }, 5);
                          // navigate("/");
                          window.location.pathname = "/";
                          window.location.hash = "#placements";
                          // window.reload();
                        }}
                        role="button"
                        onMouseOver={() => {
                          setShowPlacementDropDown(true);
                        }}
                        onMouseOut={() => {
                          setShowPlacementDropDown(false);
                        }}
                        data-bs-toggle="dropdown"
                        aria-expanded={showPlacementDropDown ? "true" : "false"}
                      >
                        PLACEMENTS
                      </a>

                      <div
                        className={
                          showPlacementDropDown
                            ? "dropdown-menu show"
                            : "dropdown-menu"
                        }
                        aria-labelledby="navbarDropdown"
                        style={{
                          overflow: "hidden",
                          width: "80vh",
                          // marginTop: "1vh",
                          backgroundColor: "lightgrey",
                          marginLeft: window.innerWidth > 1443 ? "" : "-50vh",
                        }}
                        onMouseOver={() => {
                          setShowPlacementDropDown(true);
                        }}
                        onMouseOut={() => {
                          setShowPlacementDropDown(false);
                        }}
                      >
                        <Grid
                          container
                          style={{
                            // padding: "15px",
                            boxShadow: "0 10px 20px hsla(0, 0%, 0%, 0.4)",
                          }}
                          justifyContent="center"
                        >
                          <Grid container>
                            <Grid
                              lg={6}
                              xs={6}
                              sm={6}
                              md={6}
                              style={{ padding: "15px" }}
                            >
                              <Box textAlign="center">
                                <h5
                                  style={{
                                    fontWeight: "bold",
                                    textDecoration: "underline",
                                    textDecorationColor: "orangered",
                                    textDecorationLine: "3px",
                                    lineHeight: "2px",
                                    padding: "15px",
                                    marginTop: "3vh",
                                  }}
                                >
                                  Our Placements
                                </h5>
                              </Box>
                              <p
                                style={{
                                  fontWeight: "bold",
                                  fontFamily: "Italic",
                                }}
                              >
                                Our Placement coordinators are thoroughly
                                connected with the top recruiters in and around
                                the world to achieve the top placement for our
                                students of ISHM.
                              </p>
                            </Grid>
                            <Grid
                              lg={6}
                              xs={6}
                              sm={6}
                              md={6}
                              style={{
                                padding: "15px",
                                height: "100%",
                                width: "100%",
                              }}
                            >
                              <img
                                src={PlacementImg}
                                alt=""
                                style={{ height: "100%", width: "100%" }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </li>
                    <li className="nav-item ">
                      <a
                        className="nav-link"
                        href="/#news&notice"
                        onClick={() => {
                          setShowClass(false);
                        }}
                      >
                        NEWS & NOTICE
                      </a>
                    </li>
                    {showClass ? (
                      <li className="nav-item ">
                        <a
                          href="#"
                          style={{
                            backgroundColor: "transparent",
                            textDecoration: "none",
                            boxShadow: "0 10px 20px hsla(0, 0%, 0%, 0.4)",
                            left: "50%",
                            top: "45vh",
                            position: "relative",
                          }}
                          onClick={() => {
                            setShowClass(false);
                          }}
                        >
                          Close X
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    <li className="nav-item navbtn">
                      <button className="btn ">ONLINE ADMISSION</button>
                    </li>
                    <li className="nav-item navbtn">
                      <button className="btn ">
                        <CallIcon style={{ fontSize: "18" }} />
                        CALL US
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Header;
