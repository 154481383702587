import React,{useEffect} from "react";
// import React from 'react'
import video1 from '../assets/Films of NCHM.mp4';
import banner1 from '../assets/Image/bannerimg1.png';
import banner2 from '../assets/Image/bannerimg2.png';
import banner3 from '../assets/Image/bannerimg3.png';
import banner4 from '../assets/Image/bannerimg4.png';
import "bootstrap-icons/font/bootstrap-icons.css";
import AOS from 'aos';
import "aos/dist/aos.css";

const Banner = () => {
    useEffect(() => {
        AOS.init({
          duration : 2000
        });
      }, []);
  return (
    <div>
        <div className='bannercontainer'>
        <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item carousel-item-box active ishmbannervideoactive">
    {/* <img src={banner1} alt='banner' className='ishmbannerimg'/> */}
    


    <video className='ishmbannervideo' autoplay="autoplay" loop autoPlay muted>
            <source src={video1} type="video/mp4" />
          </video>





    <h1>WELCOME TO ISHM</h1>
    </div>
    <div class="carousel-item carousel-item-box">
    <img src={banner2} alt='banner' className='ishmbannerimg '/>
    <h1>HOTEL & HOSPITALITY</h1>
    </div>
    <div class="carousel-item carousel-item-box">
    <img src={banner3} alt='banner' className='ishmbannerimg'/>
    <h1>WELCOME TO ISHM</h1>
    </div>
    <div class="carousel-item carousel-item-box">
    <img src={banner4} alt='banner' className='ishmbannerimg'/>
    <h1>AVIATION & TOURISM</h1>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
    <span class=" " aria-hidden="true"><i class="bi bi-arrow-left-short"></i> </span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
    <span class="" aria-hidden="true"><i class="bi bi-arrow-right-short"></i> </span>
    
  </button>
   
</div>
            
        </div>




            <div className='score'>
                <div className='scorecontainer container'>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="scorecontain">
                        <div className="scorecontainbox">
                          <div className="scorebox">
                            <h2 data-aos="fade-up">599+</h2> 
                            <p  data-aos="zoom-out">PLACEMENT</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="scorecontain">
                        <div className="scorecontainbox">
                          <div className="scorebox">
                            <h2 data-aos="fade-left">599+</h2> 
                            <p data-aos="zoom-in">INTERNSHIP</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="scorecontain">
                        <div className="scorecontainbox">
                          <div className="scorebox">
                            <h2 data-aos="fade-down">599+</h2> 
                            <p data-aos="slide-up">SCHOLARSHIP</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="scorecontain">
                        <div className="scorecontainbox">
                          <div className="scorebox">
                            <h2 data-aos="zoom-out">599+</h2> 
                            <p data-aos="fade-up">EDUCATION LOAN</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>



        
        


{/* <div className="item" data-aos="fade-up">1</div>
<div className="item" data-aos="fade-down">2</div>
<div className="item" data-aos="fade-right">3</div>
<div className="item" data-aos="fade-left">4</div>

<div className="item" data-aos="zoom-in">5</div>
<div className="item" data-aos="zoom-out"></div>

<div className="item" data-aos="slide-up">7</div>

<div className="item" data-aos="flip-up">8</div>
<div className="item" data-aos="flip-down">9</div>
<div className="item" data-aos="flip-right">10</div>
<div className="item" data-aos="flip-left">11</div> */}


    </div>
    
  )
}

export default Banner