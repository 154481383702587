import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Gallery from "react-photo-gallery";
import Image from "../assets/Image/DSC05258.JPG";
import Image2 from "../assets/Image/IMG_0102.jpg";
import Image3 from "../assets/Image/IMG-20220326-WA0021.jpg";
import Image4 from "../assets/Image/IMG-20220326-WA0022.jpg";
import Image5 from "../assets/Image/images (14).jpeg";
import Image6 from "../assets/Image/giphy (1).gif";
import Image7 from "../assets/Image/IMG-20220326-WA0016.jpg";
import Image8 from "../assets/Image/IMG_4704.jpg";
import Image9 from "../assets/Image/IMG_4637.jpg";
import Image10 from "../assets/Image/HUL_6036.jpg";
import Image11 from "../assets/Image/IMG_4466.jpg";
import Image12 from "../assets/Image/KIT_8659.JPG";
import Image13 from "../assets/Image/giphy.gif";
import AOS from "aos";
import "aos/dist/aos.css";

const GalleryComponent = () => {
  
  const photos = [
    {
      src: Image,
      width: 1,
      height: 1,
    },
    {
      src: Image2,
      width: 1,
      height: 1,
    },
    {
      src: Image3,
      width: 1,
      height: 1,
    },
    {
      src: Image4,
      width: 1,
      height: 1,
    },
    {
      src: Image5,
      width: 1,
      height: 1,
    },
    {
      src: Image6,
      width: 2,
      height: 1,
    },
    {
      src: Image7,
      width: 1,
      height: 1,
    },
    {
      src: Image8,
      width: 1,
      height: 1,
    },
    {
      src: Image9,
      width: 1,
      height: 1,
    },
    {
      src: Image10,
      width: 1,
      height: 1,
    },
    {
      src: Image11,
      width: 1,
      height: 1,
    },
    {
      src: Image12,
      width: 1,
      height: 1,
    },
    {
      src: Image13,
      width: 2,
      height: 1,
    },
    
  ];
//   AOS.init({
//     duration: 2000,
//   });
// }, []);
  return (
    <div className="galleryboxc">
      <div className=" container">
        <div className="row" id="gallery">
          <div className="col-lg-10 col-sm-6 col-xs-6">
            {" "}
            <h1>
              <span style={{ fontWeight: "bold" }}>OUR</span> GALLERY{" "}
            </h1>
          </div>
          <div
            className="col-lg-2 col-sm-6 col-xs-6"
            style={{ textAlign: "center" }}
          >
            {/* <div className="row" style={{ textAlign: "center" }}>
              <div className="col-lg-6 col-sm-6 col-xs-6">
                {" "}
                <button
                  style={{
                    backgroundColor: "#fb6401",
                    border: "none",
                    color: "white",
                  }}
                >
                  <ArrowBackIcon />
                </button>
              </div>
              <div className="col-lg-6 col-sm-6 col-xs-6">
                <button
                  style={{
                    backgroundColor: "#fb6401",
                    border: "none",
                    color: "white",
                  }}
                >
                  <ArrowForwardIcon />
                </button>
              </div>
            </div> */}
          </div>{" "}
        </div>
        <div className="row gallerysection">
          <Gallery  data-aos="fade-up" photos={photos} direction={"row"} />;
        </div>
      </div>
    </div>
  );
};

export default GalleryComponent;
{/* <div className="item" data-aos="fade-up">1</div>
<div className="item" data-aos="fade-down">2</div>
<div className="item" data-aos="fade-right">3</div>
<div className="item" data-aos="fade-left">4</div>

<div className="item" data-aos="zoom-in">5</div>
<div className="item" data-aos="zoom-out"></div>

<div className="item" data-aos="slide-up">7</div>

<div className="item" data-aos="flip-up">8</div>
<div className="item" data-aos="flip-down">9</div>
<div className="item" data-aos="flip-right">10</div>
<div className="item" data-aos="flip-left">11</div> */}