import logo from "./logo.svg";
import "./App.css";
import HomePage from "./pages/HomePage";
import React, { useEffect, useState } from "react";
import { Router } from "@reach/router";
import Coursespage from "./pages/CoursesPage";
import Header from "./components/header";
import Footer from "./components/footer";
import Loader from "./assets/Image/ishm_loader.gif";
import { Button, Grid, Container, Box, Typography, Link } from "@mui/material";
import NewHeader from "./components/newHeader";

function App() {
  const [loading, setLoading] = useState(false);
  const [loadingOnceDone, setLoadingOnceDone] = useState(0);
  // let scrolableId = localStorage.getItem("SID");
  // useEffect(() => {
  //   document.getElementById(scrolableId).scrollIntoView({
  //     behavior: "smooth",
  //   });
  // }, [scrolableId]);

  let path = window.location.pathname;

  useEffect(() => {
    if (path == "/") {
      if (loadingOnceDone == 0) {
        setLoading(false);
        setLoadingOnceDone(1);
      }
    } else {
      setLoading(false);
    }
  }, [path]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 4500);
  }, []);

  const width = window.innerWidth;

  return (
    <div className="App">
      {loading ? (
        <Grid container>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            textAlign="center"
            className="loader"
          >
            <Box
              display="flex"
              justifyContent="center"
              style={{
                height: "100%",
                width: "100%",
                margin: "auto",
                display: "-webkit-box",
                display: "-ms-flexbox",
              }}
            >
              <Box alignSelf="center">
                <img
                  src={Loader}
                  alt="loader"
                  style={{
                    height: window.innerWidth < 500 ? "100%" : "",
                    width: window.innerWidth < 500 ? "100%" : "",
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <>
          {" "}
          {/* {width > 990 ? <Header /> : <NewHeader />} */}
          <Header />
          <Router>
            <HomePage path="/" />
            <Coursespage path="/course_details" />
          </Router>
          <Footer />{" "}
        </>
      )}
    </div>
  );
}

export default App;
