import React from "react";
import top10 from "../assets/Image/DSC05178.JPG";
import onlinecla from "../assets/Image/IMG-20220326-WA0022.jpg";
import awarded from "../assets/Image/HUL_6036.jpg";
import wine from "../assets/Image/IMG_4466.jpg";
import carnival from "../assets/Image/DSC05258.JPG";
import "bootstrap-icons/font/bootstrap-icons.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "../assets/css/news.css";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const NewsNotice = () => {
  return (
    <div className="newsparentdiv">
      <div className="news-container container">
        <div className="row">
          <div className="col-lg-4 col-sm-3 col-xs-6">
            {" "}
            <h1 style={{ color: "white" }} id="news&notice">
              <span style={{ fontWeight: "bold" }}>NEWS</span> & NOTICE
            </h1>
          </div>
          <div
            className="col-lg-2 col-sm-6 col-xs-6"
            style={{ textAlign: "center" }}
          >
            <div className="row" style={{ textAlign: "center" }}></div>
          </div>{" "}
        </div>
        <div className="newssection">
          <Carousel responsive={responsive}>
            <div className="newsslider">
              <div className="card">
                <img
                  src={top10}
                  class="card-img-top"
                  style={{  borderTopLeftRadius: ".25rem", bordetropRightRadius: ".25re", padding: "8px" }}
                  alt="..."
                />
                <div className="datebox">
                  <sapn className="datedot"></sapn>
                  <h6>27 Jun 2020</h6>
                </div>
                <div class="card-body">
                  <h5 class="card-title">Top 10 Must watch...</h5>
                  <h6 class="card-subtitle mb-2 text-muted">
                    {" "}
                    Congratulations ISHMians..{" "}
                  </h6>
                  <button className="readmode">READ MORE <ArrowRightAltIcon /></button>
                </div>
              </div>
            </div>
            <div className="newsslider">
              <div className="card">
                <img
                  src={onlinecla}
                  class="card-img-top"
                  style={{  borderTopLeftRadius: ".25rem", bordetropRightRadius: ".25re", padding: "8px" }}
                  alt="..."
                />
                <div className="datebox">
                  <sapn className="datedot"></sapn>
                  <h6>25 May 2020</h6>
                </div>
                <div class="card-body">
                  <h5 class="card-title">ISHM Online Classes...</h5>
                  <h6 class="card-subtitle mb-2 text-muted">
                    ISHM Solutions..
                  </h6>
                  <button className="readmode">READ MORE <ArrowRightAltIcon /></button>
                </div>
              </div>
            </div>
            <div className="newsslider">
              <div className="card">
                <img
                  src={awarded}
                  class="card-img-top"
                  style={{  borderTopLeftRadius: ".25rem", bordetropRightRadius: ".25re", padding: "8px" }}
                  alt="..."
                />
                <div className="datebox">
                  <sapn className="datedot"></sapn>
                  <h6>5 Apr 2020</h6>
                </div>
                <div class="card-body">
                  <h5 class="card-title">ISHM Awarded the...</h5>
                  <h6 class="card-subtitle mb-2 text-muted">
                    ISHM awarded the...
                  </h6>
                  <button className="readmode">READ MORE <ArrowRightAltIcon /></button>
                </div>
              </div>
            </div>
            <div className="newsslider">
              <div className="card">
                <img
                  src={wine}
                  class="card-img-top"
                  style={{  borderTopLeftRadius: ".25rem", bordetropRightRadius: ".25re", padding: "8px" }}
                  alt="..."
                />
                <div className="datebox">
                  <sapn className="datedot"></sapn>
                  <h6>5 Mar 2020</h6>
                </div>
                <div class="card-body">
                  <h5 class="card-title">ISHM Wine Festival...</h5>
                  <h6 class="card-subtitle mb-2 text-muted">
                    ISHM Wine Festival...
                  </h6>
                  <button className="readmode">READ MORE <ArrowRightAltIcon /></button>
                </div>
              </div>
            </div>
            <div className="newsslider">
              <div className="card">
                <img
                  src={carnival}
                  class="card-img-top"
                  style={{    padding: "8px" }}
                  alt="..."
                />
                <div className="datebox">
                  <sapn className="datedot"></sapn>
                  <h6>27 Jun 2020</h6>
                </div>
                <div class="card-body">
                  <h5 class="card-title">ISHM Carnival...</h5>
                  <h6 class="card-subtitle mb-2 text-muted">
                    ISHM Carnival...
                  </h6>
                  <button className="readmode">READ MORE <ArrowRightAltIcon /></button>
                </div>
              </div>
            </div>
          </Carousel>
          ;
        </div>



         {/* <div class="testimonial-card">
    <div class="text">Lorem ipsum dolor sit amet consectetur elit. Ipsa voluptatum ipsum, laudantium minima aliquam porro? Iste,  Et, esta ah <i class="fas fa-quote-right quote"></i></div>
    
    
    
    <div class="footer">
      <div class="image">
       
      </div>
       <h3 class='person'>Jane Doe</h3>
      
      
    </div>
  </div> */}




      </div>
    </div>
  );
};

export default NewsNotice;
