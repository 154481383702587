import React from "react";
import banner1 from "../assets/Image/bannerimg1.png";
import banner2 from "../assets/Image/bannerimg2.png";
import banner3 from "../assets/Image/bannerimg3.png";
import banner4 from "../assets/Image/bannerimg4.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import airIndiaImg from "../assets/placement/5f9be5876eda5d373d7f91a8faf5e659.jpg";
import apolloImg from "../assets/placement/220px-Apollo_Hospitals_Svg_Logo.svg.png";
import malaysiyaAir from "../assets/placement/587b51ad44060909aa603a8f.png";
import emiratesAir from "../assets/placement/1200px-Emirates_logo.svg.png";
import etihadAir from "../assets/placement/1200px-Etihad-airways-logo.svg.png";

import riuHotels from "../assets/placement/1200px-RIU_Hotels_logo.svg.png";
import spiceJet from "../assets/placement/1200px-SpiceJet_logo.svg.png";
import airAsia from "../assets/placement/2000px-AirAsia_New_Logo.svg.png";
import citySeasons from "../assets/placement/11635017-city-seasons.jpg";
import theRaviz from "../assets/placement/74796895_1793915817419205_4253506241524924416_n.jpg";

import acciona from "../assets/placement/Acciona_logo.png";
import bigBasket from "../assets/placement/bigbasket-logo.png";
import adityaBirla from "../assets/placement/Aditya_Birla_Group_logo.png";
import amriHospital from "../assets/placement/AMRI-Hospital-logo.png";
import amazon from "../assets/placement/amazon_logo_RGB.jpg";

import byjus from "../assets/placement/Byjus-Logo.png";
import coxAndKings from "../assets/placement/Cox_and_Kings_logo.svg.png";
import futureGroup from "../assets/placement/1200px-Etihad-airways-logo.svg.png";
import expedia from "../assets/placement/expedia-logo.png";
import theFern from "../assets/placement/fern-group-of-hotels-squarelogo-1495799847945.png";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1500 },
    items: 5,
  },
  miniesktop: {
    breakpoint: { max: 1500, min: 1194 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1194, min: 919 },
    items: 3,
  },
  largemobile: {
    breakpoint: { max: 919, min: 767 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
  // largemobile: {
  //   breakpoint: { max: 919, min: 656 },
  //   items: 2,
  // },
  // mobile: {
  //   breakpoint: { max: 656, min: 0 },
  //   items: 1,
  // },
};

const Placements = () => {
  return (
    <div className="placements-container">
      <div className=" container">
        <div className="row">
          <div className="col-lg-10 col-sm-6 col-xs-6">
            {" "}
            <h1>
              <span style={{ fontWeight: "bold" }} id="placements">
                TOP
              </span>{" "}
              PLACEMENTS{" "}
            </h1>
          </div>
          <div
            className="col-lg-2 col-sm-6 col-xs-6"
            style={{ textAlign: "center" }}
          >
            <div className="row" style={{ textAlign: "center" }}></div>
          </div>{" "}
        </div>

        <div className="plasmentbox">
          <div id="placebigsli">
            <Carousel responsive={responsive} itemClass="image-item">
              <div className="placesliderbox">
                <div className="plasmcobox">
                  <div className=" plasment-card">
                    <img src={airIndiaImg} className="plasmentimg" alt="..." />
                  </div>
                  <div className=" plasment-card">
                    <img src={riuHotels} className="plasmentimg" alt="..." />
                  </div>
                  <div className=" plasment-card">
                    <img src={acciona} className="plasmentimg" alt="..." />
                  </div>
                  <div className=" plasment-card">
                    <img src={byjus} className="plasmentimg" alt="..." />
                  </div>
                </div>
              </div>
              <div className="placesliderbox">
                <div className="plasmcobox">
                  <div className=" plasment-card">
                    <img src={apolloImg} className="plasmentimg" alt="..." />
                  </div>
                  <div className=" plasment-card">
                    <img src={spiceJet} className="plasmentimg" alt="..." />
                  </div>
                  <div className=" plasment-card">
                    <img src={bigBasket} className="plasmentimg" alt="..." />
                  </div>
                  <div className=" plasment-card">
                    <img src={coxAndKings} className="plasmentimg" alt="..." />
                  </div>
                </div>
              </div>
              <div className="placesliderbox">
                <div className="plasmcobox">
                  <div className=" plasment-card">
                    <img src={malaysiyaAir} className="plasmentimg" alt="..." />
                  </div>
                  <div className=" plasment-card">
                    <img src={airAsia} className="plasmentimg" alt="..." />
                  </div>
                  <div className=" plasment-card">
                    <img src={adityaBirla} className="plasmentimg" alt="..." />
                  </div>
                  <div className=" plasment-card">
                    <img src={futureGroup} className="plasmentimg" alt="..." />
                  </div>
                </div>
              </div>
              <div className="placesliderbox">
                <div className="plasmcobox">
                  <div className=" plasment-card">
                    <img src={emiratesAir} className="plasmentimg" alt="..." />
                  </div>
                  <div className=" plasment-card">
                    <img src={citySeasons} className="plasmentimg" alt="..." />
                  </div>
                  <div className=" plasment-card">
                    <img src={amriHospital} className="plasmentimg" alt="..." />
                  </div>
                  <div className=" plasment-card">
                    <img src={expedia} className="plasmentimg" alt="..." />
                  </div>
                </div>
              </div>
              <div className="placesliderbox">
                <div className="plasmcobox">
                  <div className=" plasment-card">
                    <img src={etihadAir} className="plasmentimg" alt="..." />
                  </div>
                  <div className=" plasment-card">
                    <img src={theRaviz} className="plasmentimg" alt="..." />
                  </div>
                  <div className=" plasment-card">
                    <img src={amazon} className="plasmentimg" alt="..." />
                  </div>
                  <div className=" plasment-card">
                    <img src={theFern} className="plasmentimg" alt="..." />
                  </div>
                </div>
              </div>
            </Carousel>
          </div>

          <div id="placesmgsli">
            <Carousel responsive={responsive} itemClass="image-item">
              <div className="placesliderbox">
                <div className="plasmcobox">
                  <div className=" plasment-card">
                    <img src={etihadAir} className="plasmentimg" alt="..." />
                  </div>
                </div>
              </div>
              <div className="placesliderbox">
                <div className="plasmcobox">
                  <div className=" plasment-card">
                    <img src={riuHotels} className="plasmentimg" alt="..." />
                  </div>
                </div>
              </div>
              <div className="placesliderbox">
                <div className="plasmcobox">
                  <div className=" plasment-card">
                    <img src={acciona} className="plasmentimg" alt="..." />
                  </div>
                </div>
              </div>
              <div className="placesliderbox">
                <div className="plasmcobox">
                  <div className=" plasment-card">
                    <img src={byjus} className="plasmentimg" alt="..." />
                  </div>
                </div>
              </div>
              <div className="placesliderbox">
                <div className="plasmcobox">
                  <div className=" plasment-card">
                    <img src={apolloImg} className="plasmentimg" alt="..." />
                  </div>
                </div>
              </div>
              <div className="placesliderbox">
                <div className="plasmcobox">
                  <div className=" plasment-card">
                    <img src={spiceJet} className="plasmentimg" alt="..." />
                  </div>
                </div>
              </div>
              <div className="placesliderbox">
                <div className="plasmcobox">
                  <div className=" plasment-card">
                    <img src={bigBasket} className="plasmentimg" alt="..." />
                  </div>
                </div>
              </div>
              <div className="placesliderbox">
                <div className="plasmcobox">
                  <div className=" plasment-card">
                    <img src={coxAndKings} className="plasmentimg" alt="..." />
                  </div>
                </div>
              </div>
              <div className="placesliderbox">
                <div className="plasmcobox">
                  <div className=" plasment-card">
                    <img src={malaysiyaAir} className="plasmentimg" alt="..." />
                  </div>
                </div>
              </div>
              <div className="placesliderbox">
                <div className="plasmcobox">
                  <div className=" plasment-card">
                    <img src={airAsia} className="plasmentimg" alt="..." />
                  </div>
                </div>
              </div>
              <div className="placesliderbox">
                <div className="plasmcobox">
                  <div className=" plasment-card">
                    <img src={adityaBirla} className="plasmentimg" alt="..." />
                  </div>
                </div>
              </div>
              <div className="placesliderbox">
                <div className="plasmcobox">
                  <div className=" plasment-card">
                    <img src={futureGroup} className="plasmentimg" alt="..." />
                  </div>
                </div>
              </div>
              <div className="placesliderbox">
                <div className="plasmcobox">
                  <div className=" plasment-card">
                    <img src={emiratesAir} className="plasmentimg" alt="..." />
                  </div>
                </div>
              </div>
              <div className="placesliderbox">
                <div className="plasmcobox">
                  <div className=" plasment-card">
                    <img src={citySeasons} className="plasmentimg" alt="..." />
                  </div>
                </div>
              </div>
              <div className="placesliderbox">
                <div className="plasmcobox">
                  <div className=" plasment-card">
                    <img src={amriHospital} className="plasmentimg" alt="..." />
                  </div>
                </div>
              </div>
              <div className="placesliderbox">
                <div className="plasmcobox">
                  <div className=" plasment-card">
                    <img src={expedia} className="plasmentimg" alt="..." />
                  </div>
                </div>
              </div>
              <div className="placesliderbox">
                <div className="plasmcobox">
                  <div className=" plasment-card">
                    <img src={etihadAir} className="plasmentimg" alt="..." />
                  </div>
                </div>
              </div>
              <div className="placesliderbox">
                <div className="plasmcobox">
                  <div className=" plasment-card">
                    <img src={theRaviz} className="plasmentimg" alt="..." />
                  </div>
                </div>
              </div>
              <div className="placesliderbox">
                <div className="plasmcobox">
                  <div className=" plasment-card">
                    <img src={amazon} className="plasmentimg" alt="..." />
                  </div>
                </div>
              </div>
              <div className="placesliderbox">
                <div className="plasmcobox">
                  <div className=" plasment-card">
                    <img src={theFern} className="plasmentimg" alt="..." />
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Placements;
