import React, { useState, useEffect } from "react";
import Header from "../components/header";
import StudyAbroad from "../components/studyAbroad";
import Banner from "../components/banner";

import Testimonials from "../components/testimonials";
import Placements from "../components/placements";
import NewsNotice from "../components/newsNotice";

import Coursespage from "../components/coursespage";

import Footer from "../components/footer";
import Courses from "../components/courses";
import GalleryComponent from "../components/gallery";
import "bootstrap-icons/font/bootstrap-icons.css";

const HomePage = () => {
  return (
    // <div className="loader">
    //   <img src={Loader} alt="loader" />
    // </div>
    <div>
      <div id="telephone">
        <a>
          <i class="bi bi-telephone"></i> 695-658-542
        </a>
      </div>
      <div id="feedback">
        <a>Quick Enquiry</a>
      </div>
      <div id="whatsapp">
        <a>
          <i class="bi bi-whatsapp"></i>
        </a>
      </div>

      
      <Banner />
     
      <StudyAbroad />
      <Courses />

      <GalleryComponent /> 
      <Testimonials />
      <Placements />
      <NewsNotice />
     
    </div>
  );
};

export default HomePage;
